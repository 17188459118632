<!--
 * @Auth: linjituan
 * @Date: 2022-01-05 11:31:00
 * @LastEditors: linjituan
 * @LastEditTime: 2022-01-27 11:59:15
-->
<template>
  <div>
    <vxe-modal
      v-model="configVisible"
      width="60%"
      height="80%"
      class="device-config-modal"
      title="参数设置"
      @close="handleCloase"
      @hide="handleCloase"
      show-zoom
      resize
      :lock-view="false"
      :mask="false"
      :esc-closable="true"
      :position="position"
      ref="configRef"
    >
      <a-descriptions bordered :column="2">
        <a-descriptions-item v-for="item in paramsList" :key="item.key" :label="item.label">
          <a-row v-if="!offline">
            <a-col :span="16">
              <a-select
                style="width: 100%"
                v-if="item.key === '144'"
                allowClear
                placeholder="请选择GPS定位开启状态"
                v-model="item.value"
              >
                <a-select-option v-for="d in gpsOptions" :key="d.dictValue" :value="d.dictValue">{{
                  d.dictLabel
                }}</a-select-option>
              </a-select>
              <a-select
                style="width: 100%"
                v-else-if="item.key === '32'"
                allowClear
                placeholder="请选择位置汇报策略"
                v-model="item.value"
              >
                <a-select-option v-for="d in policyOptions" :key="d.dictValue" :value="d.dictValue">{{
                  d.dictLabel
                }}</a-select-option>
              </a-select>
              <a-input v-else v-model="item.value" allowClear />
            </a-col>
            <a-col :span="8">
              <a-button style="margin-left: 4px" type="primary" @click="setParams(item)">设置</a-button>
            </a-col>
          </a-row>
          <span v-else>{{ offlineMsg }}</span>
        </a-descriptions-item>
      </a-descriptions>
    </vxe-modal>
  </div>
</template>

<script>
import { parameters, setParameters } from '@/api/jt808/deviceInfo'

export default {
  data() {
    return {
      configVisible: false,
      clientId: '',
      paramsMap: {
        '1': '终端心跳发送间隔(秒)',
        '16': '主服务器 APN',
        '19': '主服务器地址(IP/域名)',
        '24': '服务器TCP端口',
        '32': '位置汇报策略', // 0：定时汇报；1：定距汇报；2：定时和定距汇报
        '39': '休眠时汇报时间间隔(秒)',
        '41': '缺省时间汇报间隔(秒)',
        '85': '最高速度(km/h)',
        '86': ' 超速持续时间(秒)',
        '128': '车辆里程表读数(1/10km)',
        '129': '车辆所在的省域',
        '130': '车辆所在的市域',
        '131': '机动车号牌',
        '132': '车牌颜色',
        '144': 'GPS定位' // 1开启GPS定位 0禁止GPS定位
      },
      paramsList: [],
      offlineMsg: '',
      position: {},
      offline: true,
      gpsOptions: [
        { dictValue: '0', dictLabel: '禁止' },
        { dictValue: '1', dictLabel: '开启' }
      ],
      policyOptions: [
        { dictValue: '0', dictLabel: '定时汇报' },
        { dictValue: '1', dictLabel: '定距汇报' },
        { dictValue: '2', dictLabel: '定时和定距汇报' }
      ]
    }
  },
  created() {
    this.setParamsList()
  },
  methods: {
    setParamsList() {
      for (const key in this.paramsMap) {
        this.paramsList.push({ label: this.paramsMap[key], value: '', key })
      }
    },
    getParameters(clientId) {
      parameters({ clientId })
        .then(res => {
          this.offline = false
          if (res.data) {
            for (const item of this.paramsList) {
              for (const key in res.data) {
                if (item.key === key) {
                  item.value = res.data[key]
                }
              }
            }
          }
        })
        .catch(err => {
          console.log('parameters_catch', err)
          if (err.code === 4000) {
            this.offline = true
            this.offlineMsg = err.msg
          } else {
            this.offline = false
          }
        })
    },
    open(record) {
      this.configVisible = true
      this.offlineMsg = ''
      this.getParameters(record.simNo)
      this.clientId = record.simNo
    },
    handleCloase() {
      this.configVisible = false
    },
    setParams(item) {
      const strKeyList = ['16', '19', '131']
      let parametersLong
      let parametersStr
      if (strKeyList.includes(item.key)) {
        parametersStr = {
          [item.key]: item.value
        }
      } else {
        parametersLong = {
          [item.key]: Number(item.value)
        }
      }
      setParameters({
        parametersLong,
        parametersStr
      }, this.clientId)
        .then(res => {})
        .catch(() => {})
    }
  }
}
</script>

<style></style>
