var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "vxe-modal",
        {
          ref: "configRef",
          staticClass: "device-config-modal",
          attrs: {
            width: "60%",
            height: "80%",
            title: "参数设置",
            "show-zoom": "",
            resize: "",
            "lock-view": false,
            mask: false,
            "esc-closable": true,
            position: _vm.position,
          },
          on: { close: _vm.handleCloase, hide: _vm.handleCloase },
          model: {
            value: _vm.configVisible,
            callback: function ($$v) {
              _vm.configVisible = $$v
            },
            expression: "configVisible",
          },
        },
        [
          _c(
            "a-descriptions",
            { attrs: { bordered: "", column: 2 } },
            _vm._l(_vm.paramsList, function (item) {
              return _c(
                "a-descriptions-item",
                { key: item.key, attrs: { label: item.label } },
                [
                  !_vm.offline
                    ? _c(
                        "a-row",
                        [
                          _c(
                            "a-col",
                            { attrs: { span: 16 } },
                            [
                              item.key === "144"
                                ? _c(
                                    "a-select",
                                    {
                                      staticStyle: { width: "100%" },
                                      attrs: {
                                        allowClear: "",
                                        placeholder: "请选择GPS定位开启状态",
                                      },
                                      model: {
                                        value: item.value,
                                        callback: function ($$v) {
                                          _vm.$set(item, "value", $$v)
                                        },
                                        expression: "item.value",
                                      },
                                    },
                                    _vm._l(_vm.gpsOptions, function (d) {
                                      return _c(
                                        "a-select-option",
                                        {
                                          key: d.dictValue,
                                          attrs: { value: d.dictValue },
                                        },
                                        [_vm._v(_vm._s(d.dictLabel))]
                                      )
                                    }),
                                    1
                                  )
                                : item.key === "32"
                                ? _c(
                                    "a-select",
                                    {
                                      staticStyle: { width: "100%" },
                                      attrs: {
                                        allowClear: "",
                                        placeholder: "请选择位置汇报策略",
                                      },
                                      model: {
                                        value: item.value,
                                        callback: function ($$v) {
                                          _vm.$set(item, "value", $$v)
                                        },
                                        expression: "item.value",
                                      },
                                    },
                                    _vm._l(_vm.policyOptions, function (d) {
                                      return _c(
                                        "a-select-option",
                                        {
                                          key: d.dictValue,
                                          attrs: { value: d.dictValue },
                                        },
                                        [_vm._v(_vm._s(d.dictLabel))]
                                      )
                                    }),
                                    1
                                  )
                                : _c("a-input", {
                                    attrs: { allowClear: "" },
                                    model: {
                                      value: item.value,
                                      callback: function ($$v) {
                                        _vm.$set(item, "value", $$v)
                                      },
                                      expression: "item.value",
                                    },
                                  }),
                            ],
                            1
                          ),
                          _c(
                            "a-col",
                            { attrs: { span: 8 } },
                            [
                              _c(
                                "a-button",
                                {
                                  staticStyle: { "margin-left": "4px" },
                                  attrs: { type: "primary" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.setParams(item)
                                    },
                                  },
                                },
                                [_vm._v("设置")]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _c("span", [_vm._v(_vm._s(_vm.offlineMsg))]),
                ],
                1
              )
            }),
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }